import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Basic404 from "pages/AuthenticationInner/Errors/Basic404";
import ListVoucher from "../project-pages/vouchers/list/ListVoucher";
import Dashboard from "../project-pages/dashboard/Dashboard";
import ListSettlements from "../project-pages/wallet/ListSettlements";
import Profile from "../project-pages/profile/Profile";
import Register from "../pages/Authentication/Register";
import EmailPinCodeVerification from "../pages/Authentication/EmailPinCodeVerification";
import ListStatements from "../project-pages/wallet/ListStatements";
import ListPendingSettlements from "../project-pages/wallet/ListPendingSettlements";
import Resellers from "../project-pages/resellers/Resellers";
import FundWallet from "../project-pages/wallet/fund/FundWallet";
import Error403 from "pages/AuthenticationInner/Errors/Error403";
import Permissions from "project-pages/permissions/Permissions";
import Home from "project-pages/Home";
import Reseller from "../project-pages/resellers/Reseller";
import ResetMyPassword from "../project-pages/password/ResetMyPassword";
import ResetPasswordPage from "../project-pages/password/ResetPasswordPage";

const authProtectedRoutes = [
  { path: "/",component: <Home/>},
  { path: "/dashboard",component: <Dashboard/>},
  { path: "/account/profile",component: <Profile/>},
  { path: "/wallet/settlements",component: <ListSettlements/>},
  { path: "/wallet/statements",component: <ListStatements/>},
  { path: "/wallet/settlements/pending",component: <ListPendingSettlements />},
  { path: "/vouchers/list",component: <ListVoucher/>},
  { path: "/email/verify",component: <EmailPinCodeVerification/>},
  { path: "/resellers/list",component: <Resellers/>},
  { path: "/resellers/wallet/fund",component: <FundWallet/>},
  { path: "/reseller/:id",component: <Reseller/>},
  { path: "/permissions",component: <Permissions/>},
  { path: "/403",component: <Error403/>},
  { path :"*",component: <Basic404/>},
];

const publicRoutes = [
  { path: "/login",component: <Login/>},
  { path: "/register",component: <Register/>},
  { path: "/logout",component: <Logout/>},
  { path: "/reset/my/password",component: <ResetMyPassword/>},
  { path: "/password/reset/:token",component: <ResetPasswordPage/>},
  { path: "/403",component: <Error403/>},
  {path :"*",component: <Basic404/>},
];

export { authProtectedRoutes, publicRoutes };